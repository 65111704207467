import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import _ from "lodash"
import router from "@/router"
import store from "@/store"
import config from "@/config"
import api from '@/api/api';
import moment from "moment";
import "@/style/app.scss";
import VueLazyload from 'vue-lazyload';
import {VueJsonp} from 'vue-jsonp';
import echarts from 'echarts'
//导入自动根据屏幕大小计算根元素的字体大小的JS
import setHtmlFontSize from  "./assets/js/rem.js"
//调用函数,实现功能
setHtmlFontSize()

Vue.prototype.$echarts = echarts
Vue.use(VueLazyload,{
  preLoad: 1.3,
  error: require('./assets/bad_pic.png'),
  attempt: 1
})
Vue.use(VueJsonp)

Vue.prototype._ = _;
Vue.prototype.$config = config
Vue.prototype.$api = api
Vue.prototype.$moment = moment

// 定义全局过滤器
Vue.filter('formatDate', function(value) {
  return value ? moment(value).format('YYYY-MM-DD') : "";
})
Vue.filter('formatDateChina', function(value) {
  return value ? moment(value).format('YYYY年MM月DD日') : "";
})
Vue.filter('formatDateChina1', function(value) {
  return value ? moment(value).format('YYYY年MM月DD日') : "";
})
Vue.filter('formatMoney', function(value) {
  return value ? value.toFixed(2) : "0.00";
})

Vue.prototype.openLoading = function (text) {
  text = text ? text : '正在加载...';
  const loading = this.$loading({ // 声明一个loading对象
    lock: true, // 是否锁屏
    text: text, // 加载动画的文字
    background: 'hsla(0,0%,100%,.9)', // 背景颜色
    target: '.sub-main', // 需要遮罩的区域
    body: true,
    customClass: 'mask' // 遮罩层新增类名
  })
  setTimeout(function () { // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
    loading.close(); // 关闭遮罩层
  }, 30000)
  return loading;
}
//权限
Vue.prototype.permission = function (key) {
  let permissions = "";
  this.$store.state.authorities.forEach(o=>{
    permissions += JSON.stringify(o);
  })
  if (key == null || key.length == 0) {
    return true
  }
  if (key.indexOf(',') != -1) {
    let flag = true;
    let p = key.split(',');
    p.forEach(o => {
      if (permissions.indexOf(o) == -1) {
        flag = false;
      }
    });
    return flag;
  } else {
    if (permissions.indexOf(key) > -1) {
      return true
    } else {
      return false
    }
  }
}
//保存按钮的权限
Vue.prototype.permissionSave = function (isNew, perAdd, perEdit) {
  return isNew ? this.permission(perAdd) : this.permission(perEdit);
}
//生产随机id
Vue.prototype.randomId = function () {
  let random = Math.floor(Math.random() * 900) + 100
  let dates = Date.now()
  let ids = dates.toString() + random
  return ids
}

Vue.config.productionTip = false;

// v-dialogDrag: 弹窗拖拽
Vue.directive('dialogDrag', { //属性名称dialogDrag，前面加v- 使用
  bind(el, binding, vnode, oldVnode) {
    const dialogHeaderEl = el.querySelector('.el-dialog__header');
    const dragDom = el.querySelector('.el-dialog');
    //dialogHeaderEl.style.cursor = 'move';
    dialogHeaderEl.style.cssText += ';cursor:move;'
    dragDom.style.cssText += ';top:0px;'

    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const sty = (function () {
      if (window.document.currentStyle) {
        return (dom, attr) => dom.currentStyle[attr];
      } else {
        return (dom, attr) => getComputedStyle(dom, false)[attr];
      }
    })()
    // console.log((sty(dragDom,'left')));
    dialogHeaderEl.onmousedown = (e) => {
      // console.log(dialogHeaderEl.offsetLeft);
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - dialogHeaderEl.offsetLeft;
      const disY = e.clientY - dialogHeaderEl.offsetTop;

      const screenWidth = document.body.clientWidth; // body当前宽度
      // console.log("body宽度：",screenWidth);
      const screenHeight = document.documentElement.clientHeight; // 可见区域高度(应为body高度，可某些环境下无法获取)
      // console.log("body高度：",screenHeight);

      const dragDomWidth = dragDom.offsetWidth; // 对话框宽度
      // console.log("对话框宽度：",dragDomWidth);
      const dragDomheight = dragDom.offsetHeight; // 对话框高度
      // console.log("对话框高度：",dragDomheight);

      const minDragDomLeft = dragDom.offsetLeft;
      // console.log("对话框左边距离：",minDragDomLeft);      
      const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth;
      // console.log("body宽-对话框左边距离-对话框宽度：",maxDragDomLeft);  

      const minDragDomTop = dragDom.offsetTop;
      // console.log("对话框上边距离：",minDragDomTop);
      const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomheight;
      // console.log("body高-对话框上边距离-对话框高度：",maxDragDomTop);  


      // 获取到的值带px 正则匹配替换
      let styL = sty(dragDom, 'left');
      // console.log("对话框left：",styL);
      let styT = sty(dragDom, 'top');
      // console.log("对话框top：",styT);

      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (styL.includes('%')) {
        styL = +document.body.clientWidth * (+styL.replace(/%/g, '') / 100);
        styT = +document.body.clientHeight * (+styT.replace(/%/g, '') / 100);
      } else {
        styL = +styL.replace(/\px/g, '');
        styT = +styT.replace(/\px/g, '');
      }
      
      document.onmousemove = function (e) {
        // 通过事件委托，计算移动的距离
        let left = e.clientX - disX;
        let top = e.clientY - disY;

        // 边界处理
        if (-(left) > minDragDomLeft) {
          left = -(minDragDomLeft);
        } else if (left > maxDragDomLeft) {
          left = maxDragDomLeft;
        }

        if (-(top) > minDragDomTop) {
          top = -(minDragDomTop);
        } else if (top > maxDragDomTop) {
          top = maxDragDomTop;
        }

        // console.log("对话框left：",styL);
        // console.log("移动距离left：",left);
        // 移动当前元素
        dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`;
      };

      document.onmouseup = function (e) {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    }
  }
})

new Vue({
    router,
    store,
    productionTip: false,
    render: h => h(App),
  }).$mount('#app')
  