import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'// progress bar style
import config from "@/config";
import path from 'path'

const resolve = (pagePath) => path.resolve(process.cwd(), `./${pagePath}`)
Vue.use(Router)

import { basicRoute } from './modules/basic'
const BasicLayout = () => import('@/layout/BasicLayout.vue')

export const constantRouter = [{
    path: '/',
    redirect: '/womusic'
  },
  {
    path: '',
    component: () => import('../views/womusic/index-old.vue'),
    children: [
      {
        path: '/womusic',
        name: 'Womusic',
      }
    ]
  },
  {
    path: '',
    component: () => import('../views/womusic/new.vue'),
    children: [
      {
        path: '/womusicnew',
        name: 'WomusicNew',
      }
    ]
  },
  {
    path: '',
    component: () => import('../views/womusic/new2.vue'),
    children: [
      {
        path: '/womusicnew2',
        name: 'WomusicNew2',
      }
    ]
  },
  {
    path: '',
    component: () => import('../views/womusic/new3.vue'),
    children: [
      {
        path: '/womusicnew3',
        name: 'WomusicNew3',
      }
    ]
  },
  {
    path: '',
    component: () => import('../views/womusic/success.vue'),
    children: [
      {
        path: '/womusic/success',
        name: 'WomusicSuccess',
      }
    ]
  },
  {
    path: '',
    component: () => import('../views/womusic/successNew.vue'),
    children: [
      {
        path: '/womusic/successnew',
        name: 'WomusicSuccess',
      }
    ]
  }
]

export const asyncRouter = [...basicRoute]

const router = new Router({
  routes: constantRouter
})

// 导航之前拦截器
router.beforeEach((to, from, next) => {
  document.title = "视频彩铃会员";
  // 修改浏览器页签图标
  const link = document.createElement('link');
  link.rel = "icon";
  link.href = ""
  document.head.append(link);
  next()
})

export default router